@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  letter-spacing: -0.011em;
  font-family: 'Inter var', sans-serif;
  font-size: 16px;
} */

.wrapper {
  position: relative;
}

.state {
  fill: #e2f1f1;
  stroke: cornflowerblue;
  stroke-width: 2;
}

.stateselected {
  fill: #06310b;
  stroke: rgb(103, 59, 85);
  stroke-width: 2;
  pointer-events: none;
}

.statesdone {
  fill: #abc42e;
  stroke: rgb(103, 59, 85);
  stroke-width: 2;
  pointer-events: none;
}


.statenothing {
  fill: #c836b7;
  stroke: rgb(250, 250, 250);
  stroke-width: 2;
  pointer-events: none;
}


.state:hover {
  fill: #d1ecec;
  stroke: cornflowerblue;
}


circle {
  pointer-events: none;
}

.tooltip {
  opacity: 0;
  position: absolute;
  top: -12px;
  left: 0;
  padding: 0.6em 1em;
  background: #fff;
  text-align: center;
  border: 1px solid #ddd;
  z-index: 10;
  transition: all 0.2s ease-out;
  pointer-events: none;
}

.tooltip:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 12px;
  height: 12px;
  background: white;
  border: 1px solid #ddd;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: translate(-50%, 50%) rotate(45deg);
  transform-origin: center center;
  z-index: 10;
}

.tooltip-country {
  margin-bottom: 0.2em;
  font-weight: 600;
}

.tooltip-value {
  font-size: 0.8em;
}

.legend-title {
  fill: #576574;
  text-anchor: middle;
  font-weight: 900;
  font-size: 0.95em;
  letter-spacing: 0.01em;
}

.legend-byline {
  fill: #576574;
  opacity: 0.6;
  text-anchor: middle;
  font-size: 0.7em;
}

.legend-value {
  fill: #576574;
  dominant-baseline: middle;
  font-size: 0.8em;
}

.legend-value:first-of-type {
  text-anchor: end;
}